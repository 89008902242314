<template>
  <div class="disclosure">
    <h1>Lyft Gold & Platinum Discount Plan</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color"><strong>Effective as of 06/20/2023</strong></p>
    <p>
      Electrify America and Lyft, Inc. (“Lyft”) have collaborated to provide this charging discount program to active
      and eligible Lyft Rewards Gold and Platinum tier drivers using electric vehicles to give rides to passengers using
      the rideshare platform operated by Lyft. By clicking “Agree” below, you agree to receive a discount on charging
      services on Electrify America’s network of electric vehicle charging stations, subject to these Promotion Terms
      and Conditions. There is no additional fee, purchase, or subscription required for your access to this promotion.
    </p>
    <p>
      The discount is applied to the prevailing Electrify America Pass pricing at your charger, in the amount of $0.14
      per kilowatt hour (kWh), where pricing is on a kWh basis. At chargers where pricing is calculated on a per-minute
      basis, the discount is $0.04 per minute for the 1-90 kilowatt (kW) power level, and $0.09 per minute for the 1-350
      kW power level, where, in both cases, your electric vehicle’s power level is determined by the maximum charge rate
      your electric vehicle can accept.
    </p>
    <p>
      After signing up, you can redeem this discount during your initiation of a charging session at any Electrify
      America charging station, exclusively by using the Electrify America app “Swipe to start” or NFC Pass feature with
      the appropriate charging plan selected. This discounted charging may not by initiated using the interface screen
      or buttons on the charger.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <ul>
      <li>
        There will be a 10-minute grace period immediately after charging has ended, during which you will not be
        assessed any parking or idle fees. Except for this grace period, the discount
        <strong>does not apply</strong> to any parking or idle fees assessed at the charging station.
      </li>
      <li>
        You must select this charging plan when initiating a charging session in order to receive the applicable
        discount. There will be no refunds provided by Electrify America or Lyft for charging services delivered under a
        different charging plan.
      </li>
      <li>
        <strong
          >This promotion is for your individual use only, with the electric vehicle that you use to provide rides to
          passengers through Lyft, for as long as you remain an active Lyft driver with Lyft Rewards Gold tier or
          Platinum tier status.</strong
        >
        If you are no longer an active Lyft driver, or you no longer qualify for Lyft Rewards Gold tier or Platinum
        tier, you may not use this promotion, and the promotion plan may be removed from your Electrify America account
        without notice, after which you will not be able to access or use this promotion on your account.
      </li>
      <li>
        You may only use this promotion with the account associated with your enrollment code. You may not share the
        benefits of this promotion with anyone else, including but not limited to any other Lyft drivers and/or
        Electrify America customers.
      </li>
      <li>
        You must follow all product, vehicle, safety, and technical documentation included with your vehicle when
        charging at an Electrify America charging station.
      </li>
    </ul>
    <p>
      <strong>
        Please note also that your use of this promotion may be further limited by agreements or other legal terms
        between you and Lyft, or you and the owner/lessor of your electric vehicle. Electrify America does not have any
        control over those other third-party agreements, and is not responsible for any such additional limitations or
        restrictions, even if they conflict with these Promotion Terms and Conditions.
      </strong>
    </p>
    <p>
      This promotion will automatically end on March 31, 2026, after which you will not be able to access or use this
      promotion on your account. This discounted charging is not returnable or redeemable for any cash value, and may
      not be sold, loaned, or otherwise distributed to any third party for any reason.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link>, and
      that your use of the Electrify America mobile app is subject to the Terms of Use accessible by selecting
      <strong>“Legal”</strong> in the Account Management portion of the mobile app (accessed by navigating to the
      <strong>Account</strong> icon in bottom right hand corner of the screen), then selecting “Legal”, and then
      <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &amp;
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link>
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services, and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      America services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this promotion at any time before its automatic termination date for
      any reason, in its sole discretion, with or without notice to you. Following the termination or expiration of this
      promotion for any reason, your access to the promotion will immediately cease, and you may not use or redeem any
      remaining portion of included charging time. At that time, your account will automatically convert to a basic
      Electrify America <strong>“Pass”</strong> membership account, at no additional fee or charge to you (neither
      one-time nor recurring). You may, but are not obligated to, sign up for a different type of subscription via the
      Electrify America website or mobile app.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Lyft gold and platinum Discount',
  metaInfo: {
    title: 'Lyft Gold and Platinum Discount Plan Disclosure | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/lyft-gold-and-platinum-discount-plan-disclosure/' }],
  },
};
</script>
